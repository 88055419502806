
export const datas = [
    {
        picture: {
            src: require('@/assets/images/capture_portfolio_damien_laitani.png'),
            alt: 'Capture d\'écran du Portfolio de Damien Laitani'
        },
        title: 'Portfolio',
        labels: {
            name: [
                'Vue.js'
            ]
        },
        text: 'Portfolio réalisé après ma formation de développeur web, on y retrouve toutes mes réalisations, mes compétences ainsi qu\'une description de mon parcours',
        links: [
            {
                link: 'https://github.com/Damien-LAITANI/portfolio',
                name: 'Github'
            }
        ]
    },
    {
        picture: {
            src: require('@/assets/images/capture_cat_to_home.png'),
            alt: 'Capture d\'écran du site Cat to home'
        },
        title: 'Cat to home',
        labels: {
            name: [
                'API REST Wordpress',
                'Vue.js'
            ]
        },
        text: 'Projet de fin de formation (o\'clock), réalisé en 1 mois. <br>Méthode <strong>Scrum</strong>. <br>Architecture <strong>Headless</strong> (API REST de Wordpress / <strong>Vue.js</strong>)',
        links: [
            {
                link: 'https://cat-to-home.fr',
                name: 'Découvrir'
            }
        ]
    },
    {
        picture: {
            src: require('@/assets/images/capture_backoffice_application.png'),
            alt: 'Capture d\'écran du Backoffice d\'application'
        },
        title: 'Backoffice d\'application',
        labels: {
            name: [
                'Laravel'
            ]
        },
        text: 'Projet réalisé après la formation, utilisant le Framework <strong>Laravel</strong>. <br>Gestion des produits d\'une entreprise fictive, mise en place d\'un <strong>CRUD</strong> avec authentification des utilisateurs',
        links: [
            {
                link: 'https://backoffice-app.damienlaitani.com',
                name: 'Découvrir'
            },
            {
                link: 'https://github.com/Damien-LAITANI/backoffice_MVC_laravel',
                name: 'Github'
            }
        ]
    },
    {
        picture: {
            src: require('@/assets/images/capture_memory_game.png'),
            alt: 'Capture d\'écran du jeu Mémorie'
        },
        title: 'Jeu du Memory',
        labels: {
            name: [
                'Javascript'
            ]
        },
        text: 'Mini jeu réalisé avant la formation O\'clock, en autodidacte, en <strong>Vanilla JS</strong>',
        links: [
            {
                link: 'https://damien-laitani.github.io/Memory-game-In-VanillaJS',
                name: 'Découvrir'
            },
            {
                link: 'https://github.com/Damien-LAITANI/Memory-game-In-VanillaJS',
                name: 'Github'
            }
        ]
    },
    {
        picture: {
            src: require('@/assets/images/capture_snake_game.png'),
            alt: 'Capture d\'écran du jeu du serpent'
        },
        title: 'Jeu du serpent',
        labels: {
            name: [
                'Javascript'
            ]
        },
        text: 'Mini jeu réalisé avant la formation O\'clock, en suivant une formation sur Udemy, en <strong>Vanilla JS</strong>',
        links: [
            {
                link: 'https://damien-laitani.github.io/Snake-game-In-VanillaJS',
                name: 'Découvrir'
            },
            {
                link: 'https://github.com/Damien-LAITANI/Snake-game-In-VanillaJS',
                name: 'Github'

            }
        ]
    },
]

export const navItems = [
    {
        link: '#a-propos',
        title: 'A propos'
    },
    {
        link: '#realisation',
        title: 'Réalisations'
    },
    {
        link: '#competences',
        title: 'Compétences'
    },
    {
        link: '#contact',
        title: 'Contact'
    },
]